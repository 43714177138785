<template>
  <div>
    <section class="hero hero-home">
      <div class="container">
        <div class="__content">
          <div class="hero-title">Раскройте потенциал сотрудников</div>
          <div class="hero-text">
            WAY2WEI — платформа по&nbsp;оценке и&nbsp;развитию эмоционального
            интеллекта для&nbsp;HR, T&D, руководителей и&nbsp;сотрудников
            на&nbsp;научной основе
          </div>
          <div class="hero-action btn-action display--flex">
            <button
              @click="openConsultModal = true"
              type="button"
              class="btn btn-o-white"
            >
              Проконсультироваться
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="index-section s-preferences bg-light">
      <div class="container">
        <div class="display--grid grid-2 s-preferences--top">
          <div class="__col align-item-center display--flex">
            <div class="s-title">
              Сокращаем время и&nbsp;экономим деньги на&nbsp;поиске, удержании,
              развитии ключевых сотрудников
            </div>
          </div>
          <div class="__col s-preferences--list">
            <img
              src="@/assets/images/s-preferences--list-image.svg"
              alt=""
              class="s-preferences--list-image"
            />
            <div class="s-preferences--list-text">
              Для&nbsp;крупного и&nbsp;среднего бизнеса
              от&nbsp;100&nbsp;человек:
            </div>
            <ul class="s-preferences--list-list">
              <li>Корпорации и&nbsp;госкорпорации</li>
              <li>Коммерческие банки</li>
              <li>Коммерческая медицина</li>
              <li>Ритейл&nbsp;— гипер/супермаркеты</li>
            </ul>
          </div>
        </div>
        <div class="s-preferences--grid">
          <div class="__inner display--grid grid-4">
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  По данным исследований в SIEMENS
                </div>
              </template>
              <div class="__item display--flex flex-column align-item-start">
                <p>Увеличивается эффективность сотрудников</p>
                <div class="__num">+46%</div>
              </div>
            </Popper>
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  По данным исследований МЦ КТК и EACEIP
                </div>
              </template>
              <div class="__item display--flex flex-column align-item-start">
                <p>Повышается прибыль компаний</p>
                <div class="__num">+28%</div>
              </div>
            </Popper>
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  По данным исследований в SIEMENS
                </div>
              </template>
              <div class="__item display--flex flex-column align-item-start">
                <p>Увеличивается вовлеченность сотрудников</p>
                <div class="__num">+139%</div>
              </div>
            </Popper>
            <Popper hover offsetDistance="8">
              <template #content>
                <div class="tooltip-content">
                  По данным исследований МЦ КТК и EACEIP
                </div>
              </template>
              <div class="__item display--flex flex-column align-item-start">
                <p>Повышаются продажи в компании</p>
                <div class="__num">+64%</div>
              </div>
            </Popper>
          </div>
        </div>
        <div class="btn-action">
          <button
            class="btn btn-accent btn-ico"
            type="button"
            @click="openVideoModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="12" fill="white" />
              <path d="M16 12.5L10 17L10 8L16 12.5Z" fill="#01AF08" />
            </svg>
            О платформе за 2 минуты
          </button>
          <a
            href="/files/WAY2WEI.pdf"
            download
            class="btn btn-o btn-sm"
            type="button"
            >Скачать презентацию</a
          >
        </div>
      </div>
    </section>

    <section class="index-section s-softskills">
      <div class="container">
        <div class="s-title">
          Эмоциональный интеллект&nbsp;— фундамент всех soft skills
        </div>
        <div class="__slider">
          <swiper
            slidesPerView="auto"
            :spaceBetween="10"
            :loop="true"
            :pagination="{
              el: '.skills-pagination',
              clickable: true,
            }"
            :navigation="{
              nextEl: '.skills-next',
              prevEl: '.skills-prev',
            }"
            :breakpoints="sSoftskillsBreakpoints"
          >
            <swiper-slide>
              <div class="__item display--flex flex-column align-item-start">
                <img
                  src="@/assets/images/svg/Creative.svg"
                  class="s-softskills--slider-image"
                  alt=""
                />
                <div class="s-softskills--slider-title">Креативность</div>
                <p class="s-softskills--slider-text">
                  Используя данные от эмоций, человек может смотреть на ситуацию
                  под другим углом и находить неожиданные решения. Коммуникация
                </p>
              </div>
            </swiper-slide>
             <swiper-slide>
              <div class="__item display--flex flex-column align-item-start">
                <img
                  src="@/assets/images/svg/Communicative.svg"
                  class="s-softskills--slider-image"
                  alt=""
                />
                <div class="s-softskills--slider-title">Коммуникация</div>
                <p class="s-softskills--slider-text">
                  Понимая, принимая и работая с эмоциональным состоянием у себя и собеседника, сотрудник лучше ориентируется в коммуникативной ситуации, не делает ошибки, учитывает ценности собеседника.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="__item display--flex flex-column align-item-start">
                <img
                  src="@/assets/images/svg/Adaptive.svg"
                  class="s-softskills--slider-image"
                  alt=""
                />
                <div class="s-softskills--slider-title">Адаптивность</div>
                <p class="s-softskills--slider-text">
                  Чтобы сориентироваться в незнакомой ситуации, можно
                  использовать эмоции как источник дополнительной информации.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="__item display--flex flex-column align-item-start">
                <img
                  src="@/assets/images/svg/Lider.svg"
                  class="s-softskills--slider-image"
                  alt=""
                />
                <div class="s-softskills--slider-title">Лидерство</div>
                <p class="s-softskills--slider-text">
                  Чтобы вести за собой команду, лидеру важно учитывать
                  актуальный эмоциональный фон коллектива, осознанно влиять на
                  него при помощи интеллекта.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="__item display--flex flex-column align-item-start">
                <img
                  src="@/assets/images/svg/Team.svg"
                  class="s-softskills--slider-image"
                  alt=""
                />
                <div class="s-softskills--slider-title">Командная работа</div>
                <p class="s-softskills--slider-text">
                  Чтобы объединить и организовать участников коллектива, решать
                  конфликты, можно учитывать постоянно меняющиеся эмоции всех
                  членов команды.
                </p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="__item display--flex flex-column align-item-start">
                <img
                  src="@/assets/images/svg/Antistress.svg"
                  class="s-softskills--slider-image"
                  alt=""
                />
                <div class="s-softskills--slider-title">
                  Стрессоустойчивость
                </div>
                <div class="s-softskills--slider-text">
                  Стресс - это источник сильных эмоций: от тревоги до страха.
                  Чтобы они не мешали работе сотрудника, нужно прорабатывать их
                  или заменять.
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination skills-pagination"></div>
            <div class="swiper-button-prev skills-prev"></div>
            <div class="swiper-button-next skills-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="index-section s-platform">
      <div class="container">
        <h1 class="s-title">
          WAY2WEI – единственная в России научная платформа оценки и развития
          эмоционального интеллекта человека и компании
        </h1>
        <div class="s-platform--grid-top">
          <div class="__inner display--grid grid-4">
            <div class="__item">
              <div class="__num">13+</div>
              <div class="__title">лет</div>
              <p>Мы проводим научные исследования для развития платформы</p>
            </div>
            <div class="__item">
              <div class="__num">50+</div>
              <div class="__title">компаний</div>
              <p>Тестируют и развивают сотрудников на нашей платформе</p>
            </div>
            <div class="__item">
              <div class="__num">100+</div>
              <div class="__title">HR-специалистов</div>
              <p>
                Стали экспертами в области ЭИ благодаря программам платформы
              </p>
            </div>
            <div class="__item">
              <div class="__num">9000+</div>
              <div class="__title">сотрудников</div>
              <p>Протестировано с помощью диагностики платформы</p>
            </div>
          </div>
        </div>
      </div>

      <img
        class="s-platform--image hidden-xs"
        src="@/assets/images/s-platform--image.webp"
        alt="Платформа WAY2WEI"
      />
      <img
        class="s-platform--image visible-xs"
        src="@/assets/images/s-platform--image-xs.webp"
        alt="Платформа WAY2WEI"
      />

      <div class="container">
        <div
          class="display--grid grid-4 s-platform--grid-bottom"
          :class="{ show: platformShow }"
          id="platformBottom"
        >
          <div @click="mobilePlatformToggle" class="__item">
            <p>Шаг 1</p>
            <div class="__title">
              Проведите диагностику эмоционального интеллекта
            </div>
            <div class="__content">
              <p>– Установите стандарт для подбора и развития сотрудников</p>
              <p>– Проведите анализ команды для улучшения качества работы</p>
              <p>
                – Определите кандидатов с перспективой роста и ускорьте процесс
                найма
              </p>
            </div>
          </div>
          <div @click="mobilePlatformToggle" class="__item">
            <p>Шаг 2</p>
            <div class="__title">
              Постройте индивидуальный план развития сотрудников
            </div>
            <div class="__content">
              <p>– Выберите график развития команды или отдела</p>
              <p>
                – Определите удобные формы взаимодействия с сотрудниками:
                онлайн, офлайн
              </p>
              <p>
                – Соберите пакет решений для укрепления сильных сторон
                сотрудников
              </p>
            </div>
          </div>
          <div @click="mobilePlatformToggle" class="__item">
            <p>Шаг 3</p>
            <div class="__title">
              Получите доступ к&nbsp;готовым решениям платформы
            </div>
            <div class="__content">
              <p>
                – Подключитесь к продуктам для индивидуальной работы сотрудников
              </p>
              <p>
                – Получите консультации и сопровождение во время развития ЭИ
              </p>
              <p>
                – Развивайте HR и T&D-специалистов как внутренних экспертов в
                области ЭИ
              </p>
            </div>
          </div>
          <div @click="mobilePlatformToggle" class="__item">
            <p>Шаг 4</p>
            <div class="__title">
              Подключите компанию к&nbsp;поддержанию ЭИ-среды
            </div>
            <div class="__content">
              <p>– Выберите решения для непрерывного развития ЭИ</p>
              <p>
                – Делайте периодические замеры показателей при помощи
                диагностики
              </p>
              <p>
                – Следите за обновлениями платформы (статьи, вебинары,
                конференции)
              </p>
            </div>
          </div>
        </div>
        <div class="btn-action display--flex">
          <a
            id="platformMore"
            @click.prevent="togglePlatform"
            v-show="!platformShow"
            href="#"
            class="btn-more"
            >Узнать больше</a
          >
          <div>
            <button @click="openDemoModal = true" class="btn btn-accent">Заказать демо платформы</button>
            <button
              id="platformLess"
              type="button"
              class="btn btn-o btn-sm"
              @click="togglePlatform"
              v-if="platformShow"
            >
              Скрыть
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="index-section s-goals bg-light">
      <div class="container">
        <div class="s-title">Какие задачи помогает решить наша платформа?</div>
        <div class="display--grid grid-3 s-goals--grid">
          <div
            class="__item"
            @click="goalsTab = 1"
            :class="{ active: goalsTab == 1 }"
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 134 154"
              style="enable-background: new 0 0 134 154"
              xml:space="preserve"
            >
              <path
                class="st0"
                d="M96.1,70.7L82,80.2l-2.7-1l13.8-9.6L96.1,70.7z"
              />
              <path
                class="st0"
                d="M100.2,62.5l-14.7,9.9l-0.3-2.6l13.7-9.2L100.2,62.5z"
              />
              <path
                class="st0"
                d="M103.6,70.8l-10,6.7l-0.6-2.4l9.3-6.3L103.6,70.8z"
              />
              <path
                class="st0"
                d="M69.4,6.5h6.7c0,0,0.3,3.5-0.7,5.1c0,0-2.1,3.2-2.4,3.2C73.1,14.8,69.1,10.5,69.4,6.5z"
              />
              <path
                class="st0"
                d="M93.3,84.7c-0.3-0.2-0.7-0.2-1,0l-25.7,15.6L40.2,83.3c-0.3-0.2-0.7-0.2-1,0c-0.3,0.2-0.5,0.5-0.5,0.8
                            l-0.8,44.8c0,0.3,0.2,0.6,0.4,0.8L65,147.5c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1c0.3-0.2,0.5-0.5,0.5-0.8l0.3-12.1l-1.9,0
                            L64.7,145l-24.9-16.6l0.8-42.6l25.1,16.1l0.3,9.1l1.9-0.1l-0.3-9l24.2-14.7l-1.6,40.4l1.9,0.1l1.7-42.1
                            C93.8,85.2,93.6,84.8,93.3,84.7z"
              />
              <path
                class="st0"
                d="M64.2,79l-2.5-3.6c-0.3-0.4-0.2-0.9,0.1-1.2l2.3-2.3L62,68.1c-0.2-0.3-0.2-0.6,0-0.9c0.1-0.3,0.4-0.5,0.8-0.5
                            l4.9-0.4c-2.2-6.8-6-17-7.9-18.4c-2-1.5-10.9-0.7-16,0.2l-0.9,0.2l-0.3-1.9l0.9-0.2c1.4-0.2,14.1-2.2,17.4,0.2
                            c3.2,2.3,8.4,18.7,9,20.5c0.1,0.3,0,0.6-0.1,0.8C69.5,67.9,69.3,68,69,68l-4.7,0.4l1.8,3.2c0.2,0.4,0.1,0.8-0.2,1.1L63.8,75l2,2.9
                            L64.2,79z"
              />
              <path
                class="st0"
                d="M68.3,101.3l-0.7-0.7c-0.4-0.4-1.1-1.1-1.9-1.9c-3.5-3.4-6.8-6.6-7.5-7.9c-0.7-1.2-0.8-2.5-0.4-3.7
                            c0.5-1.3,1.6-2.3,3.1-2.9L62,84l0.7,1.8l-0.9,0.3c-1,0.4-1.8,1-2,1.8c-0.2,0.6-0.1,1.3,0.3,2.1c0.6,1.1,4.8,5.2,7.1,7.5
                            c0.8,0.8,1.5,1.5,1.9,1.9l0.7,0.7L68.3,101.3z"
              />
              <path
                class="st0"
                d="M41.9,74.3c4.2,0,7.5-3.4,7.5-7.5c0-4.2-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5C34.4,71,37.8,74.3,41.9,74.3z"
              />
              <path
                class="st0"
                d="M59.7,52.6L54.9,50c-0.2-0.1-0.5-0.2-0.7-0.1l-8,2.4c-0.4,0.1-0.7,0.5-0.7,0.9s0.2,0.8,0.6,0.9l8.9,3.6
                            c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.2l3.9-3.3c0.2-0.2,0.4-0.5,0.3-0.8S60,52.8,59.7,52.6z M55.1,55.7l-5.8-2.3l5-1.5
                            l0.4,0.2l0.7,3.4L55.1,55.7z"
              />
              <path
                class="st0"
                d="M132.2,151.1c-7.3-0.6-14.3-2.3-20.7-5c2.1-2,3.7-4.7,4.6-7.7c2.6-8.8-3.4-20.9-6.6-27.3c-0.4-0.8-0.7-1.4-1-2
                            c-1-2.1-1.3-3.6-1-4.4c0.3-0.8,1.3-1.4,3-2.1c3.9-1.6,6.5-4.9,7.3-9.2c1-5.5-1.3-12.1-5.5-15.9c-1.9-1.8-4.4-3.2-6.8-4.5
                            c-4-2.2-7.7-4.3-9.3-8C94,60,94.9,54,95.6,48.7l0.1-0.6c0.1-0.5,3.1-13.2,3.6-18.5c0.6-6-1.3-12.6-1.4-12.9l-0.3-0.9l-1.8,0.5
                            l0.3,0.9c0,0.1,1.9,6.5,1.4,12.2c-0.5,5.2-3.6,18.1-3.6,18.3l-0.1,0.7C93,54,92.1,60.2,94.4,65.6c1.9,4.3,6.1,6.7,10.1,8.9
                            c2.4,1.3,4.7,2.6,6.5,4.2c3.7,3.4,5.7,9.3,4.9,14.2c-0.4,2.6-1.9,6-6.1,7.7c-1.8,0.8-3.4,1.5-4,3.1c-0.5,1.4-0.2,3.3,1,5.9
                            c0.3,0.6,0.6,1.3,1,2c3.1,6.3,8.8,17.9,6.5,26c-0.9,3-2.5,5.5-4.7,7.4c-9.5-4.4-17.7-11-24.1-19.5c-2.1-2.8-3-6.2-4-9.8
                            c-0.5-1.7-0.9-3.5-1.6-5.1c-2.4-6.6-7.3-7.3-7.5-7.3l-1.3-0.2l1.5,9c-5.6-2.9-14.4-5.6-19.2-0.6c-1.7,1.8-2.5,4.4-2.1,7.2
                            c0.6,3.6,3.3,9.1,13.1,14.5c2.8,1.5,5.4,3,7.9,4.5c2.9,1.6,5.6,3.2,8,4.5c1.9,1,3.7,2.1,5.4,3.1c5.8,3.5,10.9,6.4,17.2,5.1
                            c2.5-0.5,4.8-1.6,6.8-3c6.9,3,14.3,4.9,22.2,5.5l0.9,0.1l0.2-1.9L132.2,151.1z M102.7,148.6c-5.6,1.2-10.3-1.6-15.8-4.9
                            c-1.8-1-3.6-2.1-5.5-3.1c-2.4-1.2-5.1-2.8-7.9-4.4c-2.5-1.4-5.1-2.9-7.9-4.5c-6.6-3.6-9.7-7.2-11.1-9.9c1.9,1.3,4.7,3.2,8,4.9
                            c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.7-0.2,0.8-0.5c0.2-0.5,0.1-1-0.4-1.3c-5-2.6-9-5.7-9.9-6.4c-0.2-1.2-0.1-2.3,0.3-3.4
                            c1.7,1.3,6.3,4.4,13.2,8.1c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.7-0.2,0.8-0.5c0.2-0.5,0.1-1-0.4-1.3c-7.1-3.8-11.8-7-13.3-8.1
                            c0.1-0.2,0.3-0.3,0.4-0.5c0.4-0.5,1-0.8,1.5-1.1c1.5,0.9,6.8,4,12.4,7c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.2,0.8-0.5
                            c0.3-0.5,0.1-1-0.4-1.3c-4.5-2.4-8.7-4.8-11-6.2c0.7-0.1,1.4-0.2,2.1-0.2c4.5,0,9.7,2.3,12.6,4.1l1.8,1.1l-1.8-10.3
                            c1.3,0.6,3.3,2.1,4.6,5.6c0.6,1.6,1.1,3.3,1.5,5c1,3.6,2,7.3,4.3,10.5c6.4,8.5,14.5,15.1,23.9,19.6
                            C106.3,147.5,104.5,148.2,102.7,148.6z"
              />
              <path
                class="st0"
                d="M35.9,51l0.6,0.7l1.5-1.2l-0.6-0.7c-4.8-5.7-13.5-7.4-19.9-3.8c-2.3,1.3-4,2.9-5.7,4.4C8.7,53.2,6,55.6,1,55.6
                            H0v1.9h1c5.7,0,8.9-2.9,12-5.7c1.6-1.5,3.3-3,5.4-4.1c1.7-1,3.5-1.5,5.4-1.6c-0.3,4.2,0.1,8.8,1.2,13.8c0.9,4,2.2,8,3.8,12.1
                            c0.6,1.5,1.4,3,2.2,4.4c1.2,2.2,2.5,4.6,3,6.9c0.4,1.7,0.3,3.2-0.4,4.2c-0.6,0.8-1.5,1.4-2.9,1.7c-1.5,0.3-3.6-0.2-5.4,0.7
                            c-2.6,1.3-2.2,5.1-1.8,7.8c0,0.3,0.1,0.6,0.1,0.9c0.6,5.8,0.6,9.8,0.3,16c-0.5,9.8-11.5,14.2-11.6,14.2l-0.9,0.3l0.7,1.8l0.9-0.3
                            c0.5-0.2,12.3-4.8,12.9-15.9c0.3-6.3,0.4-10.4-0.3-16.3c0-0.3-0.1-0.6-0.1-0.9c-0.3-2.1-0.7-5.2,0.7-5.9c1.3-0.6,3.2-0.2,4.9-0.5
                            c1.9-0.4,3.2-1.2,4.1-2.5c0.9-1.4,1.2-3.4,0.7-5.6c-0.6-2.6-1.9-5-3.2-7.4c-0.8-1.5-1.5-2.8-2.1-4.2c-1.6-3.9-2.9-7.9-3.7-11.8
                            c-1.1-4.8-1.4-9.3-1.2-13.4C29.6,46.2,33.4,48,35.9,51z"
              />
              <path
                class="st0"
                d="M80.1,47.6c-0.3-0.4-0.9-0.6-1.3-0.3L74.3,50c-0.1-1.9-0.3-3.8-0.7-5.5l4.5-1.5l-0.6-1.8l-4.4,1.5
                            c-0.4-1.4-0.8-2.7-1.3-3.9c1.8-1.6,3-3.5,3.5-5.6c0.8-3.8-0.8-8.9-8.1-10.9L66.3,22l-0.5,1.8l0.9,0.2c6,1.6,7.4,5.5,6.7,8.6
                            c-0.9,4.2-5.7,8.1-11.8,6.9c-10-1.9-13.3-9.3-16.8-17.1c-1.3-2.9-2.6-5.9-4.3-8.7C36.1,6.4,29.3,1.4,22.4,0.3
                            C16.9-0.6,11.6,1,7.1,4.9L6.3,5.5l1.2,1.4l0.7-0.6c4.1-3.5,8.9-4.9,13.8-4.1c6.4,1,12.7,5.7,16.8,12.5c1.7,2.7,3,5.7,4.2,8.5
                            c3.5,8,7.2,16.2,18.2,18.2c0.8,0.2,1.7,0.2,2.5,0.2c2.5,0,4.7-0.7,6.5-1.8c0.4,1.1,0.8,2.2,1.1,3.3l-6.5,2.2l0.6,1.8l6.3-2.1
                            c0.3,1.7,0.5,3.4,0.6,5.3l-4.2-0.8c-0.5-0.1-1,0.2-1.1,0.8c-0.1,0.5,0.2,1,0.8,1.1l4.5,0.9c0,14-3.8,28.8-4.8,32.5
                            c-3.4-0.3-17.7-1.9-26.4-7.5l-0.8-0.5l-1,1.6l0.8,0.5c10.2,6.6,27.3,7.8,28,7.8l0.8,0l0.2-0.7c0.5-1.9,5.2-18.4,5.1-34l5.4-3.3
                            C80.2,48.6,80.4,48,80.1,47.6z"
              />
              <path
                class="st0"
                d="M1.3,44.3L1,42.4l0.9-0.2c5.1-0.9,9.3-3.6,13.1-6.3c6-4.3,13.9-3.7,19.6,1.5c2.8,2.5,4.5,3.9,8,4
                            c0.5,0,1,0,1.5-0.1c-5.8-1.8-8.1-6-10.5-10.4c-1.6-2.9-3.3-6-6.2-8.5c-2.9-2.6-6.3-3.6-9.9-3.1c-4.5,0.7-9.1,4-11.8,8.7l-0.5,0.8
                            l-1.6-1l0.5-0.8c3.1-5.2,8-8.8,13.2-9.6c4.2-0.6,8.1,0.6,11.4,3.5c3.1,2.8,4.9,5.9,6.6,9c2.8,5.1,5.3,9.6,13.4,10.2
                            c0.4,0,0.8,0.4,0.9,0.8c0.1,0.4-0.2,0.9-0.6,1c-2,0.8-4.2,1.2-6.4,1.2c-4.4,0-6.6-2.1-9.2-4.5c-5-4.6-11.9-5.1-17.2-1.4
                            c-4,2.8-8.3,5.6-13.8,6.6L1.3,44.3z"
              />
            </svg>
            <div class="__subtitle">ДЛЯ HR и T&D</div>
            <div class="__title">
              Укрепить компанию новыми кадрами и повышать компетенции
            </div>
          </div>
          <div
            class="__item"
            @click="goalsTab = 2"
            :class="{ active: goalsTab == 2 }"
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 128 141"
              style="enable-background: new 0 0 128 141"
              xml:space="preserve"
            >
              <path
                class="st0"
                d="M86.2,84l11,4c0.9-0.3,1.7-0.7,2.5-1.3l-14.6-5.3C85.3,82.3,85.7,83.2,86.2,84z"
              />
              <path
                class="st0"
                d="M84.9,79.3c0,0,0,0,0,0.1l16.3,5.9c0.5-0.5,0.8-1.1,1.2-1.7l-17.3-6.3C85,77.9,84.9,78.6,84.9,79.3z"
              />
              <path
                class="st0"
                d="M93,88.5l-3.8-1.4C90.3,87.8,91.6,88.3,93,88.5z"
              />
              <path
                class="st0"
                d="M94.2,70c-0.2,0-0.4,0-0.6,0l7,2.5C98.9,71,96.7,70,94.2,70z"
              />
              <path
                class="st0"
                d="M102.4,75.2l-12.1-4.4c-0.8,0.4-1.5,0.8-2.2,1.4l15.2,5.5C103.1,76.8,102.9,76,102.4,75.2z"
              />
              <path
                class="st0"
                d="M86.8,73.7c-0.4,0.5-0.8,1.1-1.1,1.8l17.4,6.3c0.2-0.7,0.3-1.4,0.4-2.1L86.8,73.7z"
              />
              <path
                class="st0"
                d="M57.6,140.8c-5.1,0-9-2.1-11.2-6.4c-1.5-2.7-1.3-6.1,0.5-9.3c2-3.7,5.5-6.2,9-6.4c1.8-0.1,3.6,0.2,5.3,0.6
		c2.6,0.5,5.1,0.9,7.5,0c2-0.8,4.3-6,5.5-9.8c-2.8,0-9.6-0.4-13.1-4.8c-0.2-0.3-0.3-0.7,0-1c0.2-0.3,0.6-0.4,1-0.3
		c0.1,0,8.1,2.6,12.2-0.6c-0.1-1.6-0.5-6.8-0.5-7.2c0-1.5,4.5-3.1,5.4-3.2c0.4-0.1,0.5-0.2,0.5-0.3c0.1-0.2,0.3-1.1-1.4-4
		c-2-3.5-4.4-8.4-5.6-14.7c-0.1-0.6-0.2-1.3-0.3-2.1c-1-7.3-2.9-21-23.3-24.6L48,46.5l0.3-1.7l0.8,0.1c21.6,3.8,23.7,18.8,24.7,26
		c0.1,0.8,0.2,1.5,0.3,2c1.1,6,3.5,10.7,5.4,14.1c1.5,2.7,2,4.4,1.4,5.5c-0.2,0.4-0.7,1-1.7,1.2c-1.3,0.2-3.4,1.3-4,1.8
		c0.1,1,0.3,4.9,0.5,7.3c0,0.3-0.1,0.5-0.3,0.7c-2.9,2.6-7.5,2.5-10.6,2c4.3,2.4,10.2,1.9,10.3,1.9c0.3,0,0.5,0.1,0.7,0.3
		c0.2,0.2,0.2,0.5,0.2,0.8c-0.3,1.1-3.1,10.7-6.8,12.1c-2.8,1.1-5.6,0.6-8.4,0.1c-1.7-0.3-3.3-0.6-4.9-0.5c-2.8,0.2-5.8,2.4-7.6,5.5
		c-1.5,2.7-1.7,5.6-0.5,7.7c4.3,8,13.8,5.8,21.1,2.6c4.4-2,8.4-4.5,11.8-6.7c1.5-1,3-2.1,4.4-3.2c3-2.3,6.1-4.7,9.7-6.2
		c4.2-2,9.2-1.7,13.9-1.3c5.4,0.5,11,1.7,16.6,3.7l0.8,0.3l-0.6,1.6l-0.8-0.3c-5.4-2-10.9-3.2-16.2-3.7c-4.5-0.4-9.2-0.7-13.1,1.1
		c-3.4,1.5-6.5,3.8-9.4,6.1c-1.5,1.1-3,2.3-4.5,3.3c-3.4,2.3-7.6,4.8-12,6.9C65.1,139.8,61,140.8,57.6,140.8z"
              />
              <path
                class="st0"
                d="M6.5,134.2c-3,0-5-0.3-5.2-0.4l-0.8-0.2l0.3-1.7l0.8,0.1c0.1,0,13.1,2.2,22.8-5.1c7.1-5.3,7-14.1,6.8-22.7
		c-0.1-3.6-0.1-7,0.3-10.1c0.8-5.3,3.2-8.9,7.5-11.3c2.2-1.3,4.6-2.3,6.9-3.3c2.2-1,4.6-2,6.7-3.3c4-2.2,6.5-4.1,7.8-7.6
		c0.6-1.7,1-3.5,1.4-5.4c0.5-2.2,1-4.5,1.9-6.7c1.4-3.5,3.6-5.6,6.4-6.3c5.6-1.5,11.6,3.2,11.9,3.4l0.7,0.5l-1,1.3L81.2,55
		c-0.1,0-5.6-4.3-10.4-3.1c-2.3,0.6-4,2.4-5.3,5.4c-0.8,2-1.3,4.2-1.8,6.4c-0.4,1.9-0.8,3.8-1.5,5.7c-1.7,4.3-5.2,6.5-8.6,8.5
		c-2.2,1.3-4.6,2.3-6.9,3.3c-2.2,1-4.6,2-6.7,3.3c-3.9,2.2-6,5.3-6.7,10.1c-0.4,3-0.4,6.3-0.3,9.9c0.1,9,0.3,18.3-7.5,24.1
		C19,133.3,11.5,134.2,6.5,134.2z"
              />
              <path
                class="st0"
                d="M33.3,125l-0.1-0.8c-0.1-1.1-0.3-2.3-0.8-3.6c-2-5.8-6.1-10.8-10.1-15.6c-1.6-1.9-3.2-3.8-4.6-5.8
		c-9.5-12.9-11.6-35,0.4-46c4.7-4.3,11.3-7.1,20.1-8.3l0.8-0.1l0.2,1.7l-0.8,0.1c-8.4,1.2-14.7,3.8-19.1,7.9
		C8,64.9,10.1,85.9,19.2,98.2c1.4,1.9,3,3.8,4.6,5.7c4.1,4.9,8.3,10,10.4,16.1c0.5,1.4,0.7,2.7,0.8,4l0.1,0.8L33.3,125z"
              />
              <path
                class="st0"
                d="M17.2,56.4l-0.8-0.3c-5.6-1.9-10.7-8.7-11-14.9C5,33.9,11.6,29.5,17.9,28.6c6.2-0.9,13.2,1.4,14.9,8.5
		c0.7,3,0.7,6.3-0.1,9.6l-0.2,0.8l-1.7-0.4l0.2-0.8c0.7-3,0.7-6.1,0.1-8.8c-1.4-6.1-7.5-8-13-7.2c-5.6,0.8-11.4,4.6-11.1,11
		c0.3,5.5,4.9,11.7,9.8,13.3l0.8,0.3L17.2,56.4z"
              />
              <path
                class="st0"
                d="M99.4,51.2c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3C102.6,49.7,101.2,51.2,99.4,51.2z"
              />
              <path
                class="st0"
                d="M110.4,141l-0.5-0.7c-0.3-0.4-8.2-10.9-11.3-17.7c-3.3-7.3-0.4-10.1,3-11.8c0.8-0.4,1.9-0.8,3.2-1.3
		c3.5-1.3,8.4-3.2,10-6.4c1.6-3.3,1.1-7.7,0.7-11.6c-0.1-0.8-0.2-1.7-0.3-2.5c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.4-0.2-2.7-0.3-4.1
		c0-0.1-1-15.1-0.3-15.9l0.3-0.3l0.4,0c0.1,0,3.8,0.3,8.3,0c0.8,0,1.8-0.2,2.1-0.8c0.3-0.7-0.2-1.8-0.4-2.1
		c-0.1-0.2-10.5-18.7-15.4-25.3c-5.7-7.8-13.5-11.5-24.4-11.5c-12.8,0-23.2-4.3-23.6-4.5c-10.3-4.4-9.7-19-9.6-19.6l0-0.8l1.7,0.1
		l0,0.8c0,0.1-0.6,14.1,8.6,18c0.1,0,10.5,4.3,23,4.3c11.5,0,19.7,3.9,25.8,12.1c5,6.8,15,24.7,15.5,25.5c0.1,0.2,1.2,2.1,0.4,3.7
		c-0.5,1-1.7,1.6-3.5,1.7c-3.4,0.2-6.3,0.1-7.7,0.1c0,2.8,0.5,13,0.6,14.3c0.1,1.4,0.2,2.7,0.3,4.1c0.1,1.3,0.1,2.7,0.2,4
		c0.1,0.8,0.2,1.6,0.3,2.4c0.5,4.1,1,8.7-0.8,12.5c-2,3.8-7.2,5.8-11,7.2c-1.2,0.5-2.3,0.9-3,1.2c-2.8,1.5-5.1,3.3-2.2,9.6
		c3,6.6,11,17.2,11.1,17.3l0.5,0.7L110.4,141z"
              />
              <path
                class="st0"
                d="M65,83.4c-0.1,0-0.2,0-0.2,0l-9.2-2.6L56,79l8.9,2.5l5.9-3.3l0.8,1.5l-6.2,3.5C65.3,83.3,65.1,83.4,65,83.4z"
              />
              <path
                class="st0"
                d="M69.3,73.4l-0.6-0.6c-0.1-0.1-6.7-6.4-19.3-3.7l-0.8,0.2l-0.4-1.7l0.8-0.2c13.6-3,20.6,3.8,20.9,4.1l0.6,0.6
		L69.3,73.4z"
              />
              <path
                class="st0"
                d="M62.2,44.6c-0.2,0-0.4-0.1-0.6-0.2L40.5,26.1c-0.3-0.2-0.4-0.6-0.2-0.9c0.1-0.3,0.4-0.6,0.8-0.6l23-0.3
		c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.7L63,43.8c0,0.3-0.2,0.6-0.5,0.7C62.4,44.5,62.3,44.6,62.2,44.6z M43.4,26.3L61.5,42
		l1.6-15.9L43.4,26.3z"
              />
              <path
                class="st0"
                d="M18.4,75.2l14.2,9.5l1.5-3.5l-11.5-7.6L18.4,75.2z"
              />
              <path
                class="st0"
                d="M33.1,78.9l12.7,8.5l3.1-1.3l-12.4-8.6L33.1,78.9z"
              />
              <path
                class="st0"
                d="M22.3,68.8l8.9,6l-0.5,0.8l-8.9-6L22.3,68.8z"
              />
              <path
                class="st0"
                d="M105,93.8h0.5c1.2,0,1.8-0.9,2.2-1.5c0.4-0.7,0.8-1.1,1.3-1.1c0.6,0,0.9,0.4,1.3,1.1c0.5,0.7,1,1.5,2.2,1.5
	s1.8-0.9,2.2-1.5c0.4-0.7,0.8-1.1,1.3-1.1c0.6,0,0.9,0.4,1.3,1.1c0.5,0.7,1,1.5,2.2,1.5s1.8-0.9,2.2-1.5c0.4-0.7,0.8-1.1,1.3-1.1
	h0.5v-1H123c-1.2,0-1.8,0.9-2.2,1.5c-0.4,0.7-0.8,1.1-1.3,1.1c-0.6,0-0.9-0.4-1.3-1.1c-0.5-0.7-1-1.5-2.2-1.5s-1.8,0.9-2.2,1.5
	c-0.4,0.7-0.8,1.1-1.3,1.1c-0.6,0-0.9-0.4-1.3-1.1c-0.5-0.7-1-1.5-2.2-1.5s-1.8,0.9-2.2,1.5c-0.4,0.7-0.8,1.1-1.3,1.1H105V93.8z"
              />
            </svg>
            <div class="__subtitle">ДЛЯ Топ-менеджерОВ и руководителЕЙ</div>
            <div class="__title">
              Управлять сильной командой и проявлять себя как лидер
            </div>
          </div>
          <div
            class="__item"
            @click="goalsTab = 3"
            :class="{ active: goalsTab == 3 }"
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 181 135"
              style="enable-background: new 0 0 181 135"
              xml:space="preserve"
            >
              <path
                class="st0"
                d="M121.4,44.4c-0.2,0-0.3,0-0.5,0.1c-1.9,0.2-13,0.1-19.9-0.1l-0.3,0l-0.2,0.2c-3.1,2.3-6.3,8.1-7.2,9.7l-4.4,0.4
	l-0.1,0.6c-2.2,10.3,2.8,22.7,3.8,25.1c1.1,2.8,7.5,24.4,6.7,36.1c-0.3,5-2.8,9.5-6.8,12.6c-5,3.8-11.8,5.1-18.8,3.7
	c-13.2-2.7-14.8-11.8-14.8-12.2c-1.3-6.3,2.7-7.6,7.7-9.1c2.8-0.9,5.7-1.8,7.7-3.8c3.9-4,2.6-7.7,1.3-11.7c-0.3-0.8-0.6-1.6-0.8-2.5
	l-0.2-0.6c-0.7-2.8-1.5-5.8-1.3-8.7c0.1-1.5,0.6-4.2,3.3-5c0.7-0.2,1.6-0.3,2.5-0.3c1.4-0.1,2.9-0.1,4.2-0.9c1.6-0.9,2.6-2.3,3-4.1
	c1.4-5.9-4.2-14.8-6.5-18.7l-0.2-0.3C79,54,78.4,53,77.9,52.1C72.8,43.6,67,33.9,58.5,29.2c-4.8-2.7-10.5-3.4-16.1-3.7
	c-1.2-0.1-2.4-0.1-3.7-0.2c-7.4-0.3-15-0.7-20.8-5c-3.4-2.5-5.6-6.2-5.8-9.7c-0.1-2.7,0.9-5.1,3-7l0.6-0.6l-1.1-1.3l-0.6,0.6
	c-2.5,2.3-3.8,5.2-3.6,8.4c0.2,3.9,2.7,8.1,6.5,10.9c6.2,4.6,14.5,5,21.7,5.3c1.2,0.1,2.4,0.1,3.6,0.2c5,0.3,10.7,0.8,15.3,3.5
	c8.1,4.6,13.7,14,18.7,22.3c0.6,0.9,1.1,1.9,1.7,2.8l0.2,0.3c2.3,3.6,7.6,12.2,6.3,17.4c-0.3,1.3-1.1,2.3-2.2,3
	c-0.9,0.5-2.1,0.6-3.4,0.7c-1,0-2,0.1-2.9,0.4c-2.7,0.8-4.4,3.2-4.5,6.6c-0.2,3.1,0.6,6.2,1.4,9.2l0.2,0.6c0.3,0.9,0.5,1.7,0.8,2.6
	c1.3,3.7,2.3,6.7-0.9,9.9c-1.7,1.7-4.4,2.6-7,3.4c-4.9,1.5-10.4,3.3-8.8,11.1c0.1,0.4,1.8,10.6,16.2,13.6c2,0.4,3.9,0.6,5.9,0.6
	c5.4,0,10.4-1.6,14.4-4.6c4.5-3.4,7.1-8.3,7.5-13.8c0.8-12.2-5.7-33.9-6.9-36.9c-1-2.3-5.7-13.8-3.9-23.5l2.4-0.2
	c-0.4,2.1-1,6.6,0.3,12.3c0.1,0.4,0.4,0.7,0.8,0.7c0.1,0,0.1,0,0.2,0c0.5-0.1,0.7-0.6,0.6-1c-1.5-6.8-0.2-11.9,0-12.6
	c0.4-0.7,3.7-6.8,6.7-9.3c0.5,0,0.9,0,1.4,0c-1.4,2.4-4.8,8.5-5.9,12.9c-0.1,0.5,0.2,0.9,0.6,1c0.1,0,0.1,0,0.2,0
	c0.4,0,0.7-0.3,0.8-0.7c1.2-4.9,5.5-12.1,6.2-13.3c1.1,0,2.1,0,3,0c-1.4,2.4-4.8,8.4-5.8,12.8c-0.1,0.5,0.2,0.9,0.6,1
	c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.3,0.8-0.7c1.1-4.8,5.3-11.9,6.1-13.2c1.3,0,2.5,0,3.6,0c-1.5,2.5-4.8,8.4-5.8,12.8
	c-0.1,0.5,0.2,0.9,0.6,1c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.3,0.8-0.7c1.1-4.8,5.2-11.7,6.1-13.2c1.5,0,2.7,0,3.6,0
	c-1.2,1.4-2.3,3.3-3.2,5c-2.6,4.8-1.7,9.6-0.8,14.4c0.5,2.4,0.9,5,0.9,7.4c-0.1,2.7-0.4,5.4-0.7,8c-0.2,2-0.5,4.1-0.6,6.2
	c-0.4,6.9-0.4,13.9-0.3,20.6c0.1,5.5,0.1,11.3-0.1,16.9l0,0.8l1.7,0.1l0-0.8c0.2-5.7,0.2-11.4,0.1-17c-0.1-6.7-0.1-13.7,0.3-20.5
	c0.1-2,0.4-4.1,0.6-6.1c0.3-2.6,0.6-5.4,0.7-8.2c0-2.6-0.5-5.3-0.9-7.8c-0.9-4.6-1.7-9,0.6-13.2c2-3.6,3.8-5.9,4.5-6v0
	c0.4-0.1,0.5-0.1,0.5-0.2l1.9-1.5H121.4z"
              />
              <path
                class="st0"
                d="M167.8,44.2L167.8,44.2l-0.5-0.4l-0.6,0.3c-1.1,0.6-7.8,7.8-4.5,20.4c0.7,3,3.6,7.5,4.7,7.8l0.5,0.1l0.3-0.3
	c3.8-3.3,5.9-8.5,5.9-14.1C173.6,52.5,171.4,47.3,167.8,44.2z M166.6,45.4C166.6,45.4,166.7,45.4,166.6,45.4
	C166.6,45.4,166.6,45.4,166.6,45.4L166.6,45.4z M167.2,45.9c3,2.9,4.7,7.3,4.8,12.1c0,0.3,0,0.6,0,0.9H163
	C162.7,51.5,166,47.2,167.2,45.9z"
              />
              <path
                class="st0"
                d="M61.3,97.9c-1.1,0-2.1-0.1-3-0.3c-3.9-0.8-7.2-2.8-8.1-4.3c-0.2-0.4-0.1-0.9,0.3-1.2c0.4-0.2,0.9-0.1,1.2,0.3
	c0.5,0.8,3.4,2.8,7,3.5c2.2,0.4,5.3,0.6,7.9-1.3c6.4-4.5,12.8,0,12.9,0c0.4,0.3,0.5,0.8,0.2,1.2c-0.3,0.4-0.8,0.5-1.2,0.2
	c-0.2-0.2-5.5-3.8-10.9,0C65.5,97.4,63.3,97.9,61.3,97.9z"
              />
              <path
                class="st0"
                d="M57.9,51.6c2.9,0,5.3,2.4,5.3,5.3c-0.5-0.1-1-0.2-1.5-0.2c-2.9,0-5.3,2.4-5.3,5.3c-2.2-0.6-3.8-2.7-3.8-5.1
	C52.6,54,55,51.6,57.9,51.6z"
              />
              <path
                class="st0"
                d="M14.5,49.1c-1.1,0-2.2-0.1-3.3-0.3c-5-0.9-8.8-3.8-10.7-8.3l-0.3-0.8l1.6-0.7l0.3,0.8c2.1,5,6.3,6.7,9.5,7.2
	c5.9,1,12.8-1.2,16.7-5.5c7.5-7.9,17.3-4.5,17.4-4.4l0.8,0.3l-0.6,1.6l-0.8-0.3c-0.4-0.1-8.9-3.1-15.6,4
	C25.8,46.7,20.1,49.1,14.5,49.1z"
              />
              <path
                class="st0"
                d="M68.5,22.9l17.8,6.4c1.4-0.5,2.8-1.2,4.1-2.1l-23.6-8.6C67.1,20.1,67.7,21.6,68.5,22.9z"
              />
              <path
                class="st0"
                d="M66.3,15.1c0,0.1,0,0.1,0,0.2l26.5,9.6c0.8-0.9,1.4-1.9,2-2.9L66.7,11.8C66.4,12.9,66.3,14,66.3,15.1z"
              />
              <path
                class="st0"
                d="M79.5,30l-6.2-2.2C75.1,28.9,77.3,29.7,79.5,30z"
              />
              <path
                class="st0"
                d="M81.3,0c-0.3,0-0.6,0-0.9,0l11.2,4.1C88.9,1.6,85.3,0,81.3,0z"
              />
              <path
                class="st0"
                d="M94.8,8.4L75.1,1.3c-1.3,0.6-2.5,1.4-3.6,2.3l24.7,9C96,11.1,95.5,9.8,94.8,8.4z"
              />
              <path
                class="st0"
                d="M69.4,6c-0.7,0.9-1.3,1.9-1.8,3l28.2,10.2c0.3-1.1,0.5-2.2,0.7-3.4L69.4,6z"
              />
              <path
                class="st0"
                d="M178.6,48.4c-2.2-5.8-6.4-9.9-12.2-12l-0.2-0.1l-0.3,0c-0.2,0-0.3,0-0.5,0.1l-46.1,8.4c-0.5,0.1-0.8,0.5-0.7,1
	c0.1,0.4,0.5,0.7,0.9,0.7c-1.7,2.7-5.5,9-5.1,12.4c0.4,4.2,0.8,7.2,1.3,9.8c0.3,1.7,0.4,2.8,0.4,3.4c-0.2,0.1-0.4,0.3-0.4,0.6
	c-0.1,0.5,0.3,0.9,0.7,1c39.6,5.1,47.9,6.1,49.6,6.4c0,0,0,0,0.1,0c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2
	c4.8-1,7.8-4.8,9.5-8C180.7,64.9,181.4,55.6,178.6,48.4z M175.3,71.2c-1.6,2.8-4.3,6.3-8.5,7.1c-4.1-2.6-6.9-6.8-8.5-12.8
	c-1.6-6.1-1.3-12.4,0.9-17.3c1.7-3.7,3.8-6.6,7.1-10c5.1,1.9,8.9,5.6,10.9,10.9C179.7,55.8,178.9,64.5,175.3,71.2z"
              />
              <path
                class="st0"
                d="M96.8,49l-19.1,3.5c-0.5,0.1-0.8,0.5-0.7,1c0.1,0.3,0.3,0.6,0.6,0.7c4.1,6.7,7.2,15.3,7.1,15.3H85
	c0.1,0.2,0.4,0.4,0.7,0.4l4.2,0.4c0,0,0.1,0,0.1,0c0.4,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.9-0.8-0.9l-0.3,0c-0.9-3.8-0.5-13.1-0.5-13.1
	l3.8-0.4l3.1-4.2l1.2-0.2c0.5-0.1,0.8-0.5,0.7-1C97.8,49.2,97.3,48.9,96.8,49z"
              />
            </svg>
            <div class="__subtitle">ДЛЯ СотрудникОВ</div>
            <div class="__title">
              Предотвратить выгорание и раскрывать сильные стороны
            </div>
          </div>
        </div>
        <div v-if="goalsTab == 1" class="s-goals--tab s-goals--tab-1">
          <div class="s-goals--tab-title">Для HR и T&D</div>
          <div class="s-goals--tab-grid display--grid grid-3">
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Ускорять поиск кандидатов на открытые позиции и формировать
                кадровый резерв
              </p>
            </div>
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Уменьшать текучесть кадров благодаря созданию благоприятной
                рабочей среды
              </p>
            </div>
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Развиваться как эксперт в области эмоционального интеллекта и
                менять жизнь компании
              </p>
            </div>
          </div>
          <div class="s-goals--tab-action display--flex">
            <div class="btn-action display--flex">
              <router-link to="/products/all" class="btn btn-accent"
                >Смотреть решения</router-link
              >
              <button
                @click="openConsultTasks = true"
                type="button"
                class="btn btn-l-accent btn-sm"
              >
                Консультация
              </button>
            </div>
            <router-link to="/testing" class="btn btn-l-accent btn-sm rate-btn">
              Оцените уровень ЭИ-зрелости вашей компании
            </router-link>
          </div>
        </div>

        <div v-if="goalsTab == 2" class="s-goals--tab s-goals--tab-2">
          <div class="s-goals--tab-title">
            Для топ-менеджеров и руководителей
          </div>
          <div class="s-goals--tab-grid display--grid grid-3">
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Подбирать эффективные команды с учетом
                эмоционально-интеллектуального фактора
              </p>
            </div>
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Создавать и поддерживать среду для достижения результата
                благодаря слаженной командной работе
              </p>
            </div>
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Повышать и укреплять управленческие и коммуникативные навыки
              </p>
            </div>
          </div>
          <div class="s-goals--tab-action display--flex">
            <div class="btn-action display--flex">
              <router-link to="/products/all" class="btn btn-accent"
                >Смотреть решения</router-link
              >
              <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-l-accent btn-sm"
              >
                Консультация
              </button>
            </div>
            <router-link to="/testing" class="btn btn-l-accent btn-sm rate-btn">
              Оцените уровень ЭИ-зрелости вашей компании
            </router-link>
          </div>
        </div>

        <div v-if="goalsTab == 3" class="s-goals--tab s-goals--tab-3">
          <div class="s-goals--tab-title">Для сотрудников</div>
          <div class="s-goals--tab-grid display--grid grid-3">
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>Усилить мотивацию и уровень вовлеченности в работу компании</p>
            </div>
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>
                Улучшать способность понимать интересы клиентов и заказчиков
              </p>
            </div>
            <div class="__item">
              <img src="@/assets/images/check-icon.svg" alt="" />
              <p>Настроить открытую коммуникацию внутри команды</p>
            </div>
          </div>
          <div class="s-goals--tab-action display--flex">
            <div class="btn-action display--flex">
              <router-link to="/products/all" class="btn btn-accent"
                >Смотреть решения</router-link
              >
              <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-l-accent btn-sm"
              >
                Консультация
              </button>
            </div>
            <router-link to="/testing" class="btn btn-l-accent btn-sm rate-btn">
              Оцените уровень ЭИ-зрелости вашей компании
            </router-link>
          </div>
        </div>

        <div class="s-goals--mobile">
          <div class="__subtitle">ДЛЯ HR и T&D</div>
          <div class="__title">
            Укрепить компанию новыми кадрами и повышать компетенции
          </div>
          <div class="s-goals--mobile-grid">
            <div class="__inner display--flex">
              <div class="__item">
                Ускорять поиск кандидатов на открытые позиции и формировать
                кадровый
              </div>
              <div class="__item">
                Уменьшать текучесть кадров благодаря созданию благоприятной
                рабочей среды
              </div>
              <div class="__item">
                Подбирать эффективные команды с учетом
                эмоционально-интеллектуального
              </div>
              <div class="__item">
                Ускорять поиск кандидатов на открытые позиции и формировать
                резерв
              </div>
              <div class="__item">
                Уменьшать текучесть кадров благодаря созданию благоприятной
                рабочей среды
              </div>
            </div>
            <div class="btn-action">
              <router-link to="/products/all" class="btn btn-accent"
                >Смотреть решения</router-link
              >
              <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-l-accent btn-sm"
              >
                Консультация
              </button>
            </div>
          </div>
        </div>

        <div class="s-goals--mobile">
          <div class="__subtitle">ДЛЯ Топ-менеджерОВ и руководителЕЙ</div>
          <div class="__title">
            Управлять сильной командой и проявлять себя как лидер
          </div>
          <div class="s-goals--mobile-grid">
            <div class="__inner display--flex">
              <div class="__item">
                Уменьшать текучесть кадров благодаря созданию благоприятной
                рабочей среды
              </div>
              <div class="__item">
                Подбирать эффективные команды с учетом
                эмоционально-интеллектуального
              </div>
              <div class="__item">
                Ускорять поиск кандидатов на открытые позиции и формировать
                резерв
              </div>
              <div class="__item">
                Уменьшать текучесть кадров благодаря созданию благоприятной
                рабочей среды
              </div>
            </div>
            <div class="btn-action">
              <router-link to="/products/all" class="btn btn-accent"
                >Смотреть решения</router-link
              >
              <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-l-accent btn-sm"
              >
                Консультация
              </button>
            </div>
          </div>
        </div>

        <div class="s-goals--mobile">
          <div class="__subtitle">ДЛЯ СотрудникОВ</div>
          <div class="__title">
            Предотвратить выгорание и раскрывать сильные стороны
          </div>
          <div class="s-goals--mobile-grid">
            <div class="__inner display--flex">
              <div class="__item">
                Ускорять поиск кандидатов на открытые позиции и формировать
                кадровый
              </div>
              <div class="__item">
                Уменьшать текучесть кадров благодаря созданию благоприятной
                рабочей среды
              </div>
              <div class="__item">
                Подбирать эффективные команды с учетом
                эмоционально-интеллектуального
              </div>
            </div>
            <div class="btn-action">
              <router-link to="/products/all" class="btn btn-accent"
                >Смотреть решения</router-link
              >
              <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-l-accent btn-sm"
              >
                Консультация
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="index-section s-cases">
      <div class="container">
        <div class="s-title">Кейсы наших клиентов</div>
        <div class="__slider">
          <swiper
            slidesPerView="auto"
            :spaceBetween="10"
            :loop="false"
            :pagination="{
              el: '.case-pagination',
              clickable: true,
            }"
            :navigation="{
              nextEl: '.case-arrow-next',
              prevEl: '.case-arrow-prev',
            }"
            :breakpoints="caseBreakpoints"
          >
            <swiper-slide
              class="case display--flex"
              v-for="client in staticClients"
              :key="client.id"
            >
              <div class="case-body">
                <div class="case-title">{{ client.first_name }}</div>
                <img v-if="client.image" :src="client.image.path" alt="" />
                <div class="case-goal">
                  <div class="__subtitle">Запрос:</div>
                  <p>
                    {{ client.description1 }}
                  </p>
                </div>
                <div class="case-result">
                  <div class="__subtitle">Результат:</div>
                  <p>{{ client.description2 }}</p>
                </div>
              </div>
              <div class="case-footer bg-light">
                <div class="__subtitle">Итог:</div>
                <div class="display--grid grid-4">
                  <div
                    class="__item"
                    v-for="percent in client.percentiles"
                    :key="percent.id"
                  >
                    <div class="__num">{{ percent.percent }}</div>
                    <p>{{ percent.description }}</p>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination case-pagination"></div>
            <div class="swiper-button-prev case-arrow-prev"></div>
            <div class="swiper-button-next case-arrow-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <video-ei v-if="showVideoEi" @closeVideoModal="showVideoEi = false" />
    <pop-up-form
      type="ConsultMain"
      title="Получить консультацию"
      v-if="openConsultModal"
      @closeModal="openConsultModal = false"
    />
    <pop-up-form
      type="ConsultTasks"
      postField
      companyField
      title="Получить консультацию"
      v-if="openConsultTasks"
      @closeModal="openConsultTasks = false"
    />
    <pop-up-form
      type="Demo"
      companyField
      title="Заказать демо платформы"
      v-if="openDemoModal"
      @closeModal="openDemoModal = false"
    />
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Popper from "vue3-popper";
import Sw from "swiper";

Sw.use([Navigation, Pagination]);

import VideoEi from "@/components/video-ei/VideoEi";

import "swiper/swiper-bundle.min.css";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import { useHead } from '@vueuse/head'

export default {
  name: "main-page",
  inject: ["backUrl"],
  components: {
    VideoEi,
    Swiper,
    SwiperSlide,
    PopUpForm,
    Popper,
  },
  setup() {
    useHead({
      title:
        "WAY2WEI – первая в России платформа по оценке и развитию эмоционального интеллекта для HR, T&D, руководителей и сотрудников.",
      meta: [
        {
          property: "og:description",
          content:
            "Сокращаем время и экономим деньги на поиске, удержании, развитии ключевых сотрудников, помогая раскрыть их потенциал ",
        },
        {
          property: "og:keywords",
          content:
            "WAY2WEI, эмоциональный интеллект, развитие эмоционального интеллекта, эмоциональный интеллект платформа, формирование эмоционального интеллекта, эмоциональный интеллект в бизнесе, эмоциональный интеллект сотрудников, определить эмоциональный интеллект, soft skills, оценка сотрудника",
        },
      ],
    });
    const showVideoEi = ref(false),
      platformShow = ref(false),
      goalsTab = ref(1),
      openConsultModal = ref(false),
      openDemoModal = ref(false),
      openConsultTasks = ref(false);
    const store = useStore();

    let clients = computed(() => store.state.clients);
    let staticClients = [
      {
        id: 1,
        first_name: "Сеть коммерческих медицинских учреждений",
        image: {
          path: require("@/assets/images/cases/case1.webp"),
        },
        description1:
          "Улучшение взаимоотношений в диаде «врач – пациент» и в триаде «врач – руководитель –  подчиненный».",
        description2:
          "После диагностики, обучения и внедрения практики эмоционального интеллекта в организацию повысилась эффективность лидерских решений, выросли вовлеченность сотрудников и приверженность организации. Улучшилась коммуникация между администрацией больниц/поликлиник и врачами, между высшим, средним и младшим медицинским персоналом, между медицинским персоналом и пациентами. Отлажена связь «врач - пациент»: врачи стали лучше управлять собственными эмоциями и успешнее влиять на эмоции пациентов. Пациенты приходят повторно и рекомендуют медицинские центры знакомым. Улучшилась связь «врач – руководитель – подчиненный», руководители в большей степени стали обращаться к сфере эмоций и задействовать эмоции в качестве источника мотивации, информированности, энергии.",
        percentiles: [
          {
            id: 1,
            percent: "11,1% ",
            description: "рост уровня удержания клиентов",
          },
          {
            id: 2,
            percent: "16%",
            description: "рост выручки за год",
          },
          {
            id: 3,
            percent: "0,3% ",
            description: "снижение показателя текучести кадров ",
          },
          {
            id: 4,
            percent: "8,9%",
            description: "снижение кол-ва жалоб клиентов на обслуживание",
          },
        ],
      },
      {
        id: 2,
        first_name: "Коммерческий банк ",
        image: {
          path: require("@/assets/images/cases/case2.webp"),
        },
        description1:
          "Улучшение показателей клиентского сервиса и управленческого потенциала руководителей.\n⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀",
        description2:
          "После диагностики, обучения и внедрения практики эмоционального интеллекта в организацию повысилась эффективность работы с клиентами, увеличилась лояльность постоянных клиентов и выросло количество новых клиентов. Улучшилась коммуникация между руководителями различных уровней, повысилась эффективность управленческих решений. Время проведения совещаний было сокращено за счет использования инструментов эмоционального интеллекта. Внедряются навыки ЭИ во внутренние программы обучения для персонала, элементы ЭИ используются для удержания ценных сотрудников, ротации кадров. Применяются навыки ЭИ в клиентоориентированном подходе в работе с клиентами, в работе с возражениями или сложными клиентами.",
        percentiles: [
          {
            id: 1,
            percent: "6%",
            description: "рост индекса потребительской лояльности NPS",
          },
          {
            id: 2,
            percent: "16,3%",
            description: "рост перекрестных продаж",
          },
          {
            id: 3,
            percent: "17 %",
            description: "рост количества клиентов за год",
          },
          {
            id: 4,
            percent: "9/10",
            description: "удовлетворенность клиентов качеством обслуживания ",
          },
        ],
      },
      {
        id: 3,
        first_name: "Крупный ритейлер",
        image: {
          path: require("@/assets/images/cases/case3.webp"),
        },
        description1:
          "Поиск новых решений для повышения эффективности работы офлайн сегмента ритейла, развития лидерства и корпоративной культуры.",
        description2:
          "После диагностики и внедрения практики эмоционального интеллекта в компанию улучшилась коммуникация, повысилась эффективность лидерских решений, выросла сплоченность коллектива, вовлеченность сотрудников, их приверженность компании.Замотивированные руководители после программы смогли иначе взглянуть на свой стиль руководства и пересмотреть некоторые принципы управления. Руководителипри проведении совещаний открыто используют инструменты эмоционального интеллекта.HR применяют элементы ЭИ в развитии кадрового резерва, в коучинге и организационном консультировании.Навыки ЭИ используются в развитии клиентоориентированного подхода в отношениях с клиентами офлайн магазинов, в работе со сложными, эмоционально насыщенными жалобами клиентов.",
        percentiles: [
          {
            id: 1,
            percent: "2х",
            description: "кратный рост положительных отзывов клиентов",
          },
          {
            id: 2,
            percent: "12,9%",
            description: "рост выручки за год",
          },
          {
            id: 3,
            percent: "1,3% ",
            description: "снижение показателя текучести кадров",
          },
          {
            id: 4,
            percent: "87%",
            description: "индекс потребительской лояльности NPS",
          },
        ],
      },
    ];

    let openVideoModal = () => {
      showVideoEi.value = true;
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    };
    let togglePlatform = () => {
      platformShow.value = !platformShow.value;
    };
    let mobilePlatformToggle = (e) => {
      if (window.innerWidth < 768) {
        e.currentTarget.classList.toggle("is-open");
      }
    };

    let getClients = () => store.dispatch("getClients");

    onMounted(() => {
      getClients();
    });

    // Брейкпоинты слайдеров
    const sSoftskillsBreakpoints = {
        520: {
          slidesPerView: 2,
          spaceBetween: 45,
        },
        768: {
          spaceBetween: 20,
        },
        1024: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      },
      caseBreakpoints = {
        768: {
          spaceBetween: 30,
        },
      };

    return {
      showVideoEi,
      platformShow,
      clients,
      goalsTab,
      openVideoModal,
      togglePlatform,
      getClients,
      sSoftskillsBreakpoints,
      caseBreakpoints,
      mobilePlatformToggle,
      openConsultModal,
      openDemoModal,
      openConsultTasks,
      staticClients,
    };
  },
};
</script>

<style lang="scss" scoped>
.hero-home {
  min-height: 655px;
  padding: 166px 0 80px;
  color: #ffffff;
  background-color: #313554;
  background-image: url(./../../assets/images/hero-home.webp),
    linear-gradient(180deg, #313554 0%, #3e4262 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.hero-home .__content {
  max-width: 540px;
}

.hero-home .hero-title {
  margin: 0 0 20px 0;
  font-size: 62px;
  font-weight: 300;
  line-height: 1em;
  letter-spacing: 0;
}

.hero-home .hero-text {
  margin: 0 0 30px 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2em;
}

.rate-btn{
  max-width: 300px;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .hero-home {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 555px;
    padding: 60px 0;
    background-color: #313554;
    background-image: url(./../../assets/images/hero-home--xs.png),
      linear-gradient(180deg, #313554 0%, #3e4262 100%);
    background-size: auto;
  }

  .hero-home .hero-title {
    font-size: 32px;
    line-height: 33px;
  }

  .hero-home .hero-text {
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
}

/* INDEX SECTION */

.s-preferences .s-title {
  max-width: 100%;
  margin-bottom: 0;
}

.s-preferences--list-image {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 150px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.s-preferences--list-text {
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
}

.s-preferences--list-list {
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 35px;
  list-style: none;
}

.s-preferences--list-list li {
  position: relative;
  padding-left: 12px;
}

.s-preferences--list-list li:before {
  position: absolute;
  display: block;
  content: "";
  top: 16px;
  left: 0;
  width: 4px;
  height: 4px;
  background: var(--color);
  border-radius: 4px;
}

.s-preferences--grid {
  position: relative;
  margin: 35px 0 0;
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.s-preferences--grid .tooltip-content {
  max-width: 230px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(65, 67, 82, 0.25);
  color: var(--color);
  border-radius: 10px;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: normal;
  padding: 13px;
}

.s-preferences--grid .__item {
  text-align: left;
  cursor: default;
}

@media (min-width: 1024px) {
  .s-preferences--grid .__item:hover {
    color: var(--accent);
  }
}

.s-preferences--grid .__item p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.s-preferences--grid .__item .__num {
  font-size: 58px;
  line-height: 79px;
}

.s-preferences .btn-action {
  margin-top: 35px;
}

.s-softskills--slider-image {
  width: auto;
  max-width: 100%;
  height: 140px;
  margin-bottom: 10px;
}

.s-softskills--slider-title {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 43px;
}

.s-softskills--slider-text {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

.s-platform {
  padding-top: 0;
}

.s-platform .s-title {
  max-width: 100%;
  font-weight: 300;
}

.s-platform--grid-top {
  margin-top: 30px;
  padding: 30px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.s-platform--grid-top .__item .__num {
  margin-bottom: 4px;
  font-size: 46px;
  line-height: 51px;
  letter-spacing: -0.02em;
}

.s-platform--grid-top .__item .__title {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.s-platform--grid-top .__item p {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

.s-platform--image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 30px;
}

.s-platform--grid-bottom {
  margin-bottom: 0;
}

.s-platform--grid-bottom .__item .__title {
  margin-bottom: 4px;
  font-size: 28px;
  line-height: 34px;
}

.s-platform--grid-bottom .__item > p {
  margin: 0 0 6px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.s-platform .btn-more {
  display: inline-block;
  margin-bottom: 30px;
  color: var(--color);
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
}

.s-platform .btn-more:hover {
  color: var(--accent);
}

.s-platform--grid-bottom .__content {
  transition: all 0.3s ease-in;
}

.s-platform--grid-bottom:not(.show) .__content {
  display: none;
}

.s-platform--grid-bottom .__content > p {
  margin: 12px 0 0;
  font-size: 18px;
  line-height: 22px;
}

.s-platform .btn-action {
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
}

.s-goals--grid .__item {
  cursor: pointer;
}

.s-goals--grid .__item svg {
  width: auto;
  height: 150px;
}

.s-goals--grid .__item svg {
  margin-bottom: 14px;
  fill: var(--color);
}

.s-goals--grid .__item .__title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.s-goals--grid .__item .__subtitle {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: 1em;
  text-transform: uppercase;
}

.s-goals--grid .__item:hover,
.s-goals--grid .__item.active {
  color: var(--accent);
}

.s-goals--grid .__item:hover svg,
.s-goals--grid .__item.active svg {
  fill: var(--accent);
}

.s-goals--tab {
  position: relative;
  margin-top: 55px;
  padding: 34px 36px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

.s-goals--tab::before {
  position: absolute;
  display: block;
  content: "";
  top: -14px;
  width: 28px;
  height: 28px;
  background: #ffffff;
  transform: rotate(45deg);
}

.s-goals--tab-1::before {
  left: 30px;
}

.s-goals--tab-2::before {
  left: calc((100% - (2 * var(--gap))) / 3 + 30px);
}

.s-goals--tab-3::before {
  left: calc(2 * ((100% - (2 * var(--gap))) / 3 + 30px));
}

.s-goals--tab-title {
  margin-bottom: 20px;
  font-size: 39px;
  line-height: 1em;
}

.s-goals--tab .__item img {
  display: block;
  width: auto;
  max-width: 100%;
  height: 28px;
  margin-bottom: 8px;
}

.s-goals--tab .__item p {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
}

.s-goals--tab-action {
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.s-cases .swiper-container {
  margin: 0 -20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  overflow: visible;
}

.case {
  flex-direction: column;
  justify-content: space-between;
  width: 825px;
  height: auto;
  padding: 25px 26px;
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

.case-title {
  max-width: 75%;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.case img {
  position: absolute;
  display: block;
  top: 30px;
  right: 26px;
  max-width: 200px;
  max-height: 80px;
}

.case .__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
}

.case-goal p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  word-break: break-word;
}

.case-result {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.case-result p {
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  word-break: break-word;
}

.case-footer {
  min-height: 200px;
  margin: 25px -26px -25px;
  padding: 25px 26px 15px;
}

.case-footer .__num {
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
}

.case-footer p {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

@media (min-width: 767px) {
  .s-goals--mobile {
    display: none !important;
  }
}

@media (max-width: 823px) {
  .case {
    width: 95%;
  }
}

@media (max-width: 767px) {
  .s-preferences .s-title {
    font-size: 28px;
    line-height: 34px;
  }

  .s-preferences--list-image {
    max-height: 115px;
    margin: 10px 0 15px;
  }

  .s-preferences--list-text {
    font-size: 20px;
    line-height: 26px;
  }

  .s-preferences--list-list {
    font-size: 18px;
    line-height: 28px;
  }

  .s-preferences--grid .__item {
    flex: 0 0 200px;
  }

  .s-preferences .btn-action {
    margin-top: 20px;
  }

  .s-preferences .btn-action .btn + .btn {
    margin-top: 20px;
    margin-left: 0;
  }

  .s-softskills--slider-image {
    height: 105px;
  }

  .s-softskills--slider-title {
    font-size: 20px;
    line-height: 26px;
  }

  .s-softskills--slider-text {
    font-size: 14px;
    line-height: 18px;
  }

  .s-softskills .swiper-slide {
    width: 230px;
  }

  .s-platform .s-title {
    font-size: 18px;
    line-height: 22px;
  }

  .s-platform--grid-top {
    margin-top: 45px;
  }

  .s-platform--grid-top .__item {
    flex: 0 0 220px;
  }

  .s-platform--grid-top .__item .__num {
    margin-bottom: 0;
    margin-left: -2px;
    font-size: 44px;
  }

  .s-platform--grid-top .__item .__title {
    margin-bottom: 12px;
    line-height: 1em;
  }

  .s-platform--grid-top .__item p {
    font-size: 16px;
    line-height: 19px;
  }

  .s-platform--image {
    width: 100%;
    max-width: 450px;
  }

  .s-platform--grid-bottom {
    grid-gap: 20px;
  }

  .s-platform--grid-bottom .__item + .__item {
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .s-platform .btn-more {
    display: none;
  }

  .s-platform--grid-bottom .__item > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
  }

  .s-platform--grid-bottom .__item .__title {
    position: relative;
    padding-right: 20px;
    font-size: 24px;
    line-height: 28px;
  }

  .s-platform--grid-bottom .__item .__title::after {
    position: absolute;
    display: block;
    content: "";
    right: 0;
    bottom: 9px;
    width: 9px;
    height: 9px;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    transform: rotate(45deg);
  }

  .s-platform--grid-bottom .__content > p {
    font-size: 18px;
    line-height: 22px;
  }

  .s-platform--grid-bottom .__item.is-open .__content {
    display: block;
  }

  .s-platform--grid-bottom .__item.is-open .__title::after {
    transform: rotate(-135deg);
  }

  .s-goals--grid,
  .s-goals--tab {
    display: none !important;
  }

  .s-goals--mobile .__subtitle {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
  }

  .s-goals--mobile .__title {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
  }

  .s-goals--mobile-grid {
    position: relative;
    font-size: 16px;
    line-height: 19px;
  }

  .s-goals--mobile-grid .__inner {
    grid-gap: 20px;
    gap: 20px;
  }

  .s-goals--mobile-grid .__item {
    flex: 0 0 210px;
  }

  .s-goals--mobile + .s-goals--mobile {
    margin-top: 35px;
    padding-top: 35px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .s-goals--mobile-grid .btn-action .btn {
    margin-top: 0;
  }

  .s-cases .swiper-slide {
    border-radius: 10px;
  }

  .case-title {
    max-width: calc(100% - 110px);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }

  .case img {
    max-width: 100px;
    max-height: 40px;
  }

  .case .__subtitle {
    margin-bottom: 8px;
    font-size: 11px;
    line-height: 1em;
  }

  .case-goal p {
    font-size: 16px;
    line-height: 20px;
  }

  .case-result p {
    font-size: 16px;
    line-height: 23px;
  }

  .case-footer .grid-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .case-footer .__num {
    font-size: 28px;
    line-height: 34px;
  }

  .case-footer p {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
